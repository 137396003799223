import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const BugReport = () => {
  const [formData, setFormData] = useState({
    // Input data state
    name: '',
    email: '',
    subject: '',
    description: '',
  });
  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if all fields are filled or not
    if (!formData.name || !formData.email || !formData.subject || !formData.description) {
      setMessage('Please fill in all fields.');
      return;
    }

    try {
      // Template parameter for EmailJS
      const templateParams = {
        name: formData.name,
        email: formData.email,
        subject: formData.subject,
        description: formData.description,
      };
      // To send email using EmailJS
      const response = await emailjs.send(
        'service_98zr8bp', // Replace with your EmailJS Service ID
        'template_tb2asjr', // Replace with your EmailJS Template ID
        templateParams,
        'YMt36Owz5LAP-mMj8' // Replace with your EmailJS Public Key
      );

      if (response.status === 200) {
        setMessage('Your feedback has been successfully submitted.');
        setFormData({
          name: '',
          email: '',
          subject: '',
          description: '',
        });
      } else {
        setMessage('There was an issue submitting your feedback. Please try again.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setMessage('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 p-6 text-gray-100">
      <div className="max-w-4xl mx-auto space-y-8">
        <h1 className="text-3xl font-bold text-center">Report a Bug or Provide Feedback</h1>

        <form onSubmit={handleSubmit} className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium mb-2">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Your name"
              className="w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Your email"
              className="w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label htmlFor="subject" className="block text-sm font-medium mb-2">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              placeholder="Brief description of the issue or feedback"
              className="w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium mb-2">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder="Please describe the issue or your feedback in detail"
              className="w-full p-3 bg-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 h-32"
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full px-4 py-2 bg-blue-600 rounded-lg text-white font-semibold hover:bg-blue-500 transition"
          >
            Submit
          </button>
        </form>

        {message && (
          <div
            className={`text-center mt-4 ${
              message.includes('successfully') ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default BugReport;
