import React, { useState, useEffect } from 'react';
import { useUser } from '../contexts/UserContext';
import { addTeamMember, fetchUsers } from '../services/api';

const TeamCard = ({ team, onSelect, isSelected, onMemberAdded}) => {
    const [showAddMember, setShowAddMember] = useState(false);
    const [newMember, setNewMember] = useState('');
    const { userRole } = useUser();
    const isManager = userRole === 'ADMIN';
    const isAuthorized = isManager && (team.teamLeader?.username === localStorage.getItem('username'));
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const data = await fetchUsers();
                setUsers(data);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
        loadUsers();
    }, []);


    const handleAddMember = async (e) => {
        e.stopPropagation();
        try {
            await addTeamMember(team._id, newMember);
            setNewMember('');
            setShowAddMember(false);
            onMemberAdded();
        } catch (error) {
            console.error('Failed to add member:', error);
        }
    };

    return (
        <div 
            className={`p-6 rounded-xl shadow-lg transition-all transform hover:scale-105 ${
                isSelected 
                    ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white' 
                    : 'bg-white hover:shadow-xl'
            }`}
            onClick={() => onSelect(team._id)}
        >
            <h3 className="text-2xl font-bold mb-4">{team.teamName}</h3>
            
            <div className="space-y-3">
                <div className="flex items-center gap-2">
                    <div className="p-2 bg-gray-100 rounded-full">
                        <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                        </svg>
                    </div>
                    <span className="font-medium">Team Leader:</span>
                    <span className="font-medium">{team.teamLeader?.username || 'Not assigned'}</span>
                </div>

                <div className="flex items-center gap-2">
                    <div className="p-2 bg-gray-100 rounded-full">
                        <svg className="w-5 h-5 text-gray-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </div>
                    <span className="font-medium">Members:</span>
                    <span className="font-medium">{team.members?.length || 0}</span>
                </div>
            </div>

            {isAuthorized && (
                <div onClick={e => e.stopPropagation()} className="mt-4">
                    <button 
                        className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                        onClick={() => setShowAddMember(!showAddMember)}
                    >
                        Add Member
                    </button>

                    {showAddMember && (
                        <div className="mt-3 flex gap-2">
                            <select
                                value={newMember}
                                onChange={(e) => setNewMember(e.target.value)}
                                className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white text-gray-900"
                            >
                                <option value="">Select user</option>
                                {users.map((user) => (
                                    <option key={user._id} value={user.username}>
                                        {user.username}
                                    </option>
                                ))}
                            </select>
                            <button 
                                onClick={handleAddMember}
                                className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
                            >
                                Add
                            </button>
                        </div>
                    )}

                </div>
            )}
        </div>
    );
};

export default TeamCard;
