import React, { useState, useEffect } from 'react';
import TaskTable from './TaskTable';
import { useUser } from '../contexts/UserContext';
import { 
    updateTeamOKR, 
    updateTeamSubtask, 
    deleteTeamOKR, 
    addTeamOKR, 
    addTeamSubtask, 
    deleteTeamSubtask 
} from '../services/api';

function TasksPage({ title, teamId, team, initialData = [] }) {
    const [rows, setRows] = useState(initialData || []);
    const { userRole } = useUser();
    const isAdmin = userRole === 'ADMIN';

    useEffect(() => {
        setRows(initialData || []);
    }, [initialData]);

    const calculateProgress = (subtasks) => {
        if (!subtasks || subtasks.length === 0) return 0;
        const completedTasks = subtasks.filter(task => task.progress === 'Done').length;
        return Math.round((completedTasks / subtasks.length) * 100);
    };

    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        try {
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        } catch (error) {
            console.error('Date formatting error:', error);
            return '';
        }
    };

    const addNewRow = async () => {
        try {
            const today = new Date().toISOString().split('T')[0];
            const newOKR = {
                objective: 'New Objective',
                owner: localStorage.getItem('username'),
                deadline: today,
                priority: 'Medium',
                subtasks: []
            };
            
            const response = await addTeamOKR(teamId, newOKR);
            console.log('API Response:', response);
            
            // Extract the relevant data from the response
            // If response.objective exists, use that, otherwise use the response itself
            const objectiveData = response.objective || response;
            
            // Create new row with generated temporary ID if needed
            const newRow = {
                ...objectiveData,
                _id: objectiveData._id || response._id || `temp-${Date.now()}`, // Fallback to temporary ID if needed
                subtasks: objectiveData.subtasks || []
            };
            
            setRows(prevRows => [...prevRows, newRow]);
    
            // If we used a temporary ID, we might want to refresh the data
            if (!objectiveData._id && !response._id) {
                // Optionally refresh the data from the server
                // You might want to add a function to fetch fresh data here
            }
        } catch (error) {
            console.error('Failed to add new OKR:', error);
        }
    };

    const addSubtask = async (taskIndex) => {
        try {
            const today = new Date().toISOString().split('T')[0];
            const newSubtask = {
                objective: 'New Subtask',
                owner: localStorage.getItem('username'),
                deadline: today,
                priority: 'Medium',
                progress: 'Not started'
            };

            const objectiveId = rows[taskIndex]._id;
            const response = await addTeamSubtask(teamId, objectiveId, newSubtask);
            
            const newRows = [...rows];
            newRows[taskIndex].subtasks = newRows[taskIndex].subtasks || [];
            newRows[taskIndex].subtasks.push(response.objective.subtasks[response.objective.subtasks.length - 1]);
            setRows(newRows);
        } catch (error) {
            console.error('Failed to add subtask:', error);
        }
    };

    const removeRow = async (indexToRemove) => {
        try {
            await deleteTeamOKR(teamId, rows[indexToRemove]._id);
            setRows(rows.filter((_, index) => index !== indexToRemove));
        } catch (error) {
            console.error('Failed to remove OKR:', error);
        }
    };

    const removeSubtask = async (taskIndex, subtaskIndex) => {
        try {
            await deleteTeamSubtask(teamId, rows[taskIndex]._id, rows[taskIndex].subtasks[subtaskIndex]._id);
            const newRows = [...rows];
            newRows[taskIndex].subtasks = newRows[taskIndex].subtasks.filter((_, index) => index !== subtaskIndex);
            setRows(newRows);
        } catch (error) {
            console.error('Failed to remove subtask:', error);
        }
    };

    const updateMainTask = async (taskIndex, field, value) => {
        try {
            const taskToUpdate = rows[taskIndex];
            
            // Skip update if the ID is temporary
            if (taskToUpdate._id?.startsWith('temp-')) {
                console.log('Skipping update for temporary ID');
                // Update local state only
                setRows(prevRows => {
                    const newRows = [...prevRows];
                    newRows[taskIndex] = {
                        ...newRows[taskIndex],
                        [field]: value
                    };
                    return newRows;
                });
                return;
            }
    
            // Proceed with normal update if we have a real ID
            const updatedData = { [field]: value };
            await updateTeamOKR(teamId, taskToUpdate._id, updatedData);
            
            setRows(prevRows => {
                const newRows = [...prevRows];
                newRows[taskIndex] = {
                    ...newRows[taskIndex],
                    [field]: value
                };
                return newRows;
            });
        } catch (error) {
            console.error('Failed to update main task:', error);
        }
    };

    
    const updateSubtask = async (taskIndex, subtaskIndex, field, value) => {
        try {
            const updatedData = { [field]: value };
            const response = await updateTeamSubtask(
                teamId, 
                rows[taskIndex]._id, 
                rows[taskIndex].subtasks[subtaskIndex]._id, 
                updatedData
            );
            
            const newRows = [...rows];
            newRows[taskIndex].subtasks[subtaskIndex] = response.subtask;
            setRows(newRows);
        } catch (error) {
            console.error('Failed to update subtask:', error);
        }
    };

    const formatDateForDisplay = (dateString) => {
        if (!dateString) return '';
        try {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        } catch (error) {
            console.error('Date display formatting error:', error);
            return '';
        }
    };

    const getOwnerUsername = (owner) => {
        return owner?.username || owner || '';
    };

    return (
        <div className="flex justify-center App">
            <main className="w-full p-8 max-w-screen-2xl">
                <section className="w-full max-w-full p-4 -mx-8 bg-gray-100 rounded-lg shadow">
                    <h1 className="mb-4 text-3xl font-bold">{title}</h1>
                    <TaskTable
                        rows={rows}
                        teamData={team}
                        setRows={setRows}
                        calculateProgress={calculateProgress}
                        addNewRow={isAdmin ? addNewRow : null}
                        addSubtask={isAdmin ? addSubtask : null}
                        removeRow={isAdmin ? removeRow : null}
                        updateSubtask={updateSubtask}
                        updateMainTask={updateMainTask}
                        removeSubtask={isAdmin ? removeSubtask : null}
                        formatDateForDisplay={formatDateForDisplay}
                        formatDateForInput={formatDateForInput}
                        getOwnerUsername={getOwnerUsername}
                    />
                </section>
            </main>
        </div>
    );
}

export default TasksPage;