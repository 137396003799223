import React, { useState, useEffect } from 'react';
import { fetchLoggedInUserObjectives } from '../services/api';
import TeamTasksList from '../components/TeamTasksList';

function MyWork() {
  const [teamsData, setTeamsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const username = localStorage.getItem('username');

  useEffect(() => {
    const loadTasks = async () => {
      try {
        const data = await fetchLoggedInUserObjectives(username);
        setTeamsData(data);
      } catch (error) {
        console.error('Error fetching tasks:', error);
      } finally {
        setLoading(false);
      }
    };

    if (username) {
      loadTasks();
    }
  }, [username]);

  if (loading) {
    return <div className="text-center p-8">Loading...</div>;
  }

  return (
    <div className="p-6 bg-gray-900 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-white">My Work</h1>
      {teamsData.length === 0 ? (
        <div className="text-center text-gray-400 mt-8">
          No tasks found for {username}
        </div>
      ) : (
        teamsData.map((teamData, index) => (
          <TeamTasksList key={teamData.teamId || index} teamData={teamData} />
        ))
      )}
    </div>
  );
}

export default MyWork;
