import axios from 'axios';

const API_BASE_URL = 'https://pureokr-backend.onrender.com';
// const  API_BASE_URL = 'http://localhost:56785';

const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
};

const formatObjectiveForServer = (objective) => ({
    ...objective,
    deadline: formatDate(objective.deadline),
    subtasks: objective.subtasks && Array.isArray(objective.subtasks) && objective.subtasks.length > 0
    ? objective.subtasks.map(subtask => ({
        ...subtask,
        deadline: formatDate(subtask.deadline)
      }))
    : []
});

export const normalizeObjective = (objective) => ({
    ...objective,
    deadline: formatDate(objective.deadline),
    subtasks: objective.subtasks && Array.isArray(objective.subtasks) && objective.subtasks.length > 0
        ? objective.subtasks.map(subtask => ({
            ...subtask,
            deadline: formatDate(subtask.deadline)
          }))
        : []
});

export const fetchTeams = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/teams`);
        return response.data;
    } catch (error) {
        console.error('Error fetching teams:', error);
        throw error;
    }
};

export const updateTeamOKR = async (teamId, okrIndex, updatedData) => {
    try {
        const response = await axios.patch(`${API_BASE_URL}/team/${teamId}/updateobjective/${okrIndex}`, updatedData);
        return response.data;
    } catch (error) {
        console.error('Error updating OKR:', error);
        throw error;
    }
};

export const updateTeamSubtask = async (teamId, okrIndex, subtaskIndex, updatedData) => {
    try {
        const response = await axios.patch(
            `${API_BASE_URL}/team/${teamId}/objectives/${okrIndex}/subtasks/${subtaskIndex}`, 
            updatedData
        );
        return response.data;
    } catch (error) {
        console.error('Error updating subtask:', error);
        throw error;
    }
};

export const deleteTeamOKR = async (teamId, okrIndex) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/team/${teamId}/deleteobjective/${okrIndex}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting OKR:', error);
        throw error;
    }
};

export const addTeamOKR = async (teamId, okrData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team/${teamId}/createobjective`, okrData);
        console.log('Response from server:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error adding OKR:', error);
        throw error;
    }
};

export const addTeamSubtask = async (teamId, okrIndex, subtaskData) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/team/${teamId}/objectives/${okrIndex}/subtasks`, 
            subtaskData
        );
        return response.data;
    } catch (error) {
        console.error('Error adding subtask:', error);
        throw error;
    }
};

export const addTeamMember = async (teamId, username) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/team/${teamId}/members`, { username });
        return response.data;
    } catch (error) {
        console.error('Error adding team member:', error);
        throw error;
    }
};


export const deleteTeamSubtask = async (teamId, okrIndex, subtaskIndex) => {
    try {
        const response = await axios.delete(
            `${API_BASE_URL}/team/${teamId}/objectives/${okrIndex}/subtasks/${subtaskIndex}`
        );
        return response.data;
    } catch (error) {
        console.error('Error deleting subtask:', error);
        throw error;
    }
};

export const fetchObjectives = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/getallobjectives`);
        const objectivesData = response.data.result || response.data;
        // if (!Array.isArray(objectivesData)) {
        //     throw new Error('Received data is not an array');
        // }
        
        // return objectivesData.map(objective => {
        //     const normalizedObjective = normalizeObjective(objective);
        //     console.log(`Objective ${normalizedObjective._id} has ${normalizedObjective.subtasks.length} subtasks`);
        //     return normalizedObjective;
        // });
        return objectivesData;
    } catch (error) {
        console.error('Error fetching objectives:', error);
        throw error;
    }
};

export const fetchTeamObjectives = async (teamId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/team/${teamId}/getallobjectives`);
        return response.data;
    } catch (error) {
        console.error('Error fetching team objectives:', error);
        throw error;
    }
};


export const createObjective = async (newObjective) => {
    try {
        const formattedObjective = formatObjectiveForServer(newObjective);
        const response = await axios.post(`${API_BASE_URL}/createobjective`, formattedObjective);
        return response.data;
    } catch (error) {
        console.error('Error creating objective:', error);
        throw error;
    }
};

export const updateObjective = async (objectiveId, updatedData) => {
    try {
        const response = await axios.patch(`${API_BASE_URL}/updateobjective/${objectiveId}`, updatedData);
        return response.data;
    } catch (error) {
        console.error('Error updating objective:', error);
        throw error;
    }
};

export const addSubtaskToObjective = async (objectiveId, newSubtask) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/objectives/${objectiveId}/subtasks`, newSubtask);
        return response.data; 
    } catch (error) {
        console.error('Error adding subtask:', error);
        throw error;
    }
};

export const deleteObjective = async (objectiveId) => {
    try {
        await axios.delete(`${API_BASE_URL}/deleteobjective/${objectiveId}`);
    } catch (error) {
        console.error('Error deleting objective:', error);
        throw error;
    }
};

export const deleteSubtask = async (objectiveId, subtaskId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/objectives/${objectiveId}/subtasks/${subtaskId}`);
        return response.data; 
    } catch (error) {
        console.error('Error deleting subtask:', error);
        throw error;
    }
};

export const updateSubtaskApi = async (objectiveId, subtaskId, updatedSubtask) => {
    try {
        const formattedSubtask = {
            ...updatedSubtask,  
            deadline: formatDate(updatedSubtask.deadline)
        }
        const response = await axios.patch(`${API_BASE_URL}/objectives/${objectiveId}/subtasks/${subtaskId}`, formattedSubtask);
        return response.data;
    } catch (error) {
        console.error('Error updating subtask:', error);
        throw error;
    }
};


export const fetchLoggedInUserObjectives = async (username) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/user/${username}/objectives`);
        console.log('Response data:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching user objectives:', error);
        throw error;
    }
};

export const fetchUsers = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/users`);
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};