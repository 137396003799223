import React, { useState, useEffect } from 'react';
import TasksPage from '../components/TasksPage';
import TeamCard from '../components/TeamCard';
import { fetchTeams, fetchTeamObjectives } from '../services/api';

function MyTeams() {
    const [teams, setTeams] = useState([]); // List of teams state
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [teamObjectives, setTeamObjectives] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadTeams();
    }, []);

    useEffect(() => {
        if (selectedTeam) {
            loadTeamObjectives(selectedTeam);
        }
    }, [selectedTeam]);
    
    // Fetch the list of teams from the API
    const loadTeams = async () => {
        try {
            const teamsData = await fetchTeams();
            setTeams(teamsData);
            setLoading(false);
        } catch (error) {
            console.error('Error loading teams:', error);
            setLoading(false);
        }
    };
     // Fetch the objectives of a specific team from the API
    const loadTeamObjectives = async (teamId) => {
        try {
            const data = await fetchTeamObjectives(teamId);
            setTeamObjectives(data);
        } catch (error) {
            console.error('Error loading team objectives:', error);
        }
    };

    if (loading) {
        return <div className="p-8 text-center">Loading teams...</div>;
    }

    return (
        <div className="p-8">
            <h1 className="text-3xl font-bold mb-6">My Teams</h1>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                {teams.map(team => (
                    <TeamCard 
                        key={team._id}
                        team={team}
                        onSelect={setSelectedTeam}
                        isSelected={selectedTeam === team._id}
                        onMemberAdded={loadTeams}
                    />
                ))}
            </div>

            {selectedTeam && teamObjectives && (
                <div className="mt-8">
                    <TasksPage 
                        teamId={selectedTeam}
                        team={teams.find(t => t._id === selectedTeam)}
                        title={teams.find(t => t._id === selectedTeam)?.teamName}
                        initialData={teamObjectives.objectives || []}
                    />
                </div>
            )}
        </div>
    );
}

export default MyTeams;
