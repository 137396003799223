import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "../components/card";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line, PieChart, Pie, Cell } from 'recharts';

const Home = () => {
  // Sample data
  const objectiveProgress = [
    { name: 'Sales Target', completed: 85, total: 100 },
    { name: 'Customer Satisfaction', completed: 92, total: 100 },
    { name: 'Product Innovation', completed: 65, total: 100 },
    { name: 'Team Efficiency', completed: 78, total: 100 },
  ];

  const monthlyProgress = [
    { month: 'Jan', completion: 65 },
    { month: 'Feb', completion: 72 },
    { month: 'Mar', completion: 78 },
    { month: 'Apr', completion: 85 },
    { month: 'May', completion: 82 },
    { month: 'Jun', completion: 88 },
  ];

  const teamDistribution = [
    { name: 'Completed', value: 35 },
    { name: 'In Progress', value: 45 },
    { name: 'Not Started', value: 20 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  return (
    <div className="p-4 space-y-4">
      <h1 className="text-2xl font-bold mb-6">OKR Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Objective Progress Bar Chart */}
        <Card>
          <CardHeader>
            <CardTitle>Objective Progress</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="w-full h-64">
              <BarChart
                width={500}
                height={250}
                data={objectiveProgress}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="completed" fill="#8884d8" name="Completion Rate" />
              </BarChart>
            </div>
          </CardContent>
        </Card>

        {/* Monthly Trend Line Chart */}
        <Card>
          <CardHeader>
            <CardTitle>Monthly Completion Trend</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="w-full h-64">
              <LineChart
                width={500}
                height={250}
                data={monthlyProgress}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="completion" stroke="#82ca9d" name="Completion Rate" />
              </LineChart>
            </div>
          </CardContent>
        </Card>

        {/* Team OKR Distribution Pie Chart */}
        <Card>
          <CardHeader>
            <CardTitle>Team OKR Status Distribution</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="w-full h-64">
              <PieChart width={500} height={250}>
                <Pie
                  data={teamDistribution}
                  cx={250}
                  cy={125}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                >
                  {teamDistribution.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </div>
          </CardContent>
        </Card>

        {/* Status Statistics Cards */}
        <Card>
          <CardHeader>
            <CardTitle>Overall Statistics</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-blue-100 p-4 rounded-lg">
                <p className="text-lg font-semibold">Total Objectives</p>
                <p className="text-3xl font-bold text-blue-600">24</p>
              </div>
              <div className="bg-green-100 p-4 rounded-lg">
                <p className="text-lg font-semibold">Completion Rate</p>
                <p className="text-3xl font-bold text-green-600">78%</p>
              </div>
              <div className="bg-yellow-100 p-4 rounded-lg">
                <p className="text-lg font-semibold">In Progress</p>
                <p className="text-3xl font-bold text-yellow-600">12</p>
              </div>
              <div className="bg-purple-100 p-4 rounded-lg">
                <p className="text-lg font-semibold">Teams Involved</p>
                <p className="text-3xl font-bold text-purple-600">8</p>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default Home;
