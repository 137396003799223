import React from 'react';

const TeamTasksList = ({ teamData }) => {
  return (
    <div className="mb-8 p-6 bg-gray-800 rounded-lg">
      <h2 className="text-xl font-bold mb-4 text-white">{teamData.teamName}</h2>
      
      {/* Objectives Section */}
      {teamData.objectives.length > 0 && (
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2 text-blue-400">My Objectives</h3>
          <div className="space-y-2">
            {teamData.objectives.map((obj, index) => (
              <div key={index} className="p-4 bg-gray-700 rounded">
                <div className="flex justify-between items-center">
                  <span className="text-white">{obj.objective}</span>
                  <span className={`px-2 py-1 rounded text-sm ${
                    obj.priority === 'High' ? 'bg-red-500' :
                    obj.priority === 'Medium' ? 'bg-yellow-500' : 'bg-green-500'
                  }`}>
                    {obj.priority}
                  </span>
                </div>
                <div className="text-sm text-gray-400 mt-2">
                  Due: {new Date(obj.deadline).toLocaleDateString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Subtasks Section */}
      {teamData.subtasks.length > 0 && (
        <div>
          <h3 className="text-lg font-semibold mb-2 text-green-400">My Subtasks</h3>
          {teamData.subtasks.map((group, index) => (
            <div key={index} className="mb-4">
              <div className="text-sm text-gray-400 mb-2">
                From: {group.parentObjective}
              </div>
              <div className="space-y-2">
                {group.subtasks.map((subtask, idx) => (
                  <div key={idx} className="p-4 bg-gray-700 rounded">
                    <div className="flex justify-between items-center">
                      <span className="text-white">{subtask.objective}</span>
                      <span className={`px-2 py-1 rounded text-sm ${
                        subtask.priority === 'High' ? 'bg-red-500' :
                        subtask.priority === 'Medium' ? 'bg-yellow-500' : 'bg-green-500'
                      }`}>
                        {subtask.priority}
                      </span>
                    </div>
                    <div className="text-sm text-gray-400 mt-2">
                      Due: {new Date(subtask.deadline).toLocaleDateString()}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TeamTasksList;
